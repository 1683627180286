@import 'breakpoints';
@import 'styles/index';

:root {
    --offset: 16px;
    --header-height: 64px;
}

html {
    font-family: 'NotoSans', sans-serif;
}

@include media-breakpoint-down(sm) {
    :root {
        --header-height: 56px;
    }

    // Hides help-button for mobile screens since it overlaps main buttons
    #freshworks-container {
        display: none;
    }
}
