@use '@angular/material' as mat;
@use 'sass:map';

@include mat.core();
@include mat.legacy-core();

$primary: mat.define-palette($my-primary);
$accent: mat.define-palette($my-accent);
$warn: mat.define-palette(mat.$deep-orange-palette);
$secondary: mat.define-palette($my-secondary);
$grey: mat.define-palette($my-grey);
$info: mat.define-palette($my-info);

$theme: mat.define-light-theme(
    (
        color: (
            'primary': $primary,
            'accent': $accent,
            'warn': $warn,
        ),
        typography: $main-typography,
    )
);

/*
    Since material has a new syntax for mixin usage and doesn't allow to set secondary/grey/info pallets in
    define-light-theme function, I duplicated colours here so we can use old syntax for all pallets
    in *.theme.scss files.
*/
$theme: map.merge(
    $theme,
    (
        'primary': $primary,
        'accent': $accent,
        'warn': $warn,
        'grey': $grey,
        'secondary': $secondary,
        'info': $info,
    )
);

@include mat.all-component-themes($theme);
@include mat.all-legacy-component-themes($theme);
