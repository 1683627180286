@use 'sass:map';
@use '@angular/material' as mat;

@mixin table-element-theme($theme) {
    $secondary: map.get($theme, 'secondary');
    $grey: map.get($theme, 'grey');

    .table-element {
        td {
            background-color: mat.get-color-from-palette($secondary, 200);
        }

        &__text {
            color: mat.get-color-from-palette($grey, 300);
        }
    }
}
