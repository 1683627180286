.mat-raised-button,
.mat-flat-button,
.mat-button,
.mat-stroked-button {
    border-radius: 28px !important;
}

.mat-stroked-button {
    border: 2px solid currentColor !important;

    &:not([disabled]) {
        border-color: unset;
    }
}

.mat-icon-button {
    height: 36px !important;
    width: 36px !important;
}

.mat-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
}
