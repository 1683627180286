@use 'sass:map';
@use '@angular/material' as mat;

@mixin multiline-title-element-component-theme($theme) {
    $secondary: map.get($theme, 'secondary');

    .multiline-title-element {
        &__pre-title {
            color: mat.get-color-from-palette($secondary);
        }
    }
}
