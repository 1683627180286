@use 'sass:map';
@use '@angular/material' as mat;

@mixin list-card-element-component($theme) {
    $secondary: map.get($theme, 'secondary');
    $gray: map.get($theme, 'grey');

    .list-card-element {
        &__header {
            background-color: mat.get-color-from-palette($secondary);
        }

        &__content-element {
            background-color: mat.get-color-from-palette($gray, 100);

            &:nth-child(odd) {
                background-color: mat.get-color-from-palette($gray, 50);
            }
        }
    }
}
