@use 'sass:map';
@use '@angular/material' as mat;

@mixin document-editor-container($theme) {
    $secondary: map.get($theme, 'secondary');
    $grey: map.get($theme, 'grey');

    .document-editor {
        .icon {
            background: mat.get-color-from-palette($secondary);
        }

        .firepad-toolbar {
            border-top: 1px solid mat.get-color-from-palette($grey, 100);
            border-bottom: 1px solid mat.get-color-from-palette($grey, 100);

            .firepad-btn-group {
                border-right: 1px solid mat.get-color-from-palette($grey, 100);
            }

            a.firepad-btn,
            a.firepad-btn:visited,
            a.firepad-btn:active {
                color: mat.get-color-from-palette($grey, 400);
            }

            a.firepad-btn:hover {
                background-color: mat.get-color-from-palette($grey, 200);
            }
        }
    }
}
