.ck-content {
    ul,
    ol {
        padding-left: var(--offset);
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        margin-bottom: calc(var(--offset) / 2);
    }
}
