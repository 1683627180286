@use 'sass:map';
@use '@angular/material' as mat;

@mixin mentor-script-component-theme($theme) {
    $info: map.get($theme, 'info');

    .mentorscript {
        .bubble {
            background: mat.get-color-from-palette($info);

            &:before {
                border-top: 12px solid mat.get-color-from-palette($info);
            }
        }

        .note-say .icon-wrapper .icon {
            background: mat.get-color-from-palette($info);
        }
    }
}
