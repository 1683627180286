/**
 * Cards
 * @link https://material.angular.io/components/card/overview
 */

.mat-card:not([class*='mat-elevation-z']) {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.07);
}

.w-100 {
    width: 100%;
}

.item-container {
    position: relative;

    .edit-actions {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 5px;
        z-index: 10;

        .edit {
            cursor: pointer;
            font-size: 20px;
            margin: 0 5px;
            border-radius: 50%;
            background: #fff;
            color: #71a3e8;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #71a3e8;
            transition: background 0.2s ease;

            &:hover {
                background: #71a3e8;
                color: #ffffff;
            }
        }
    }
}
