/**
 * Sidebar Navigation
 * @link https://material.angular.io/components/sidenav/overview
 */

.mat-drawer-container {
    background: transparent;
}

.mat-drawer {
    border-radius: 3px 0px 0px 3px;
}
