@use 'sass:map';
@use '@angular/material' as mat;

@mixin settings-dialog-theme($theme) {
    $grey: map.get($theme, 'grey');
    $primary: map.get($theme, 'primary');

    .settings {
        &__test-indicator,
        &__microphone-indicator {
            .mat-icon {
                background-color: mat.get-color-from-palette($grey, 200);
            }

            &--active {
                .mat-icon {
                    background-color: mat.get-color-from-palette($primary);
                }
            }
        }

        &__test-indicator {
            &:hover {
                color: mat.get-color-from-palette($primary);
            }
        }

        &__description-text {
            color: mat.get-color-from-palette($grey, 300);
        }
    }
}
