@use 'sass:map';
@use '@angular/material' as mat;

@mixin aspect-card-element-component($theme) {
    $secondary: map.get($theme, 'secondary');
    $gray: map.get($theme, 'grey');

    .aspect-card-element {
        background-color: mat.get-color-from-palette($secondary);

        &:before {
            background-color: mat.get-color-from-palette($secondary, 600);
        }
    }
}
