// Material Design Icons
@import '~material-design-icons/iconfont/material-icons.css';

@font-face {
    font-family: 'Stay';
    src: url('/assets/fonts/Stay.ttf') format('truetype');
}

@font-face {
    font-family: 'Gothic';
    src: url('/assets/fonts/SocialGothicMedium.otf') format('opentype');
}

@font-face {
    font-family: 'Corbel';
    src: url('/assets/fonts/Corbel.ttf');
}

@font-face {
    font-family: 'HandOfSeanPro';
    src: url('/assets/fonts/Sean Johnson - Hand Of Sean Pro.otf')
        format('opentype');
}

@font-face {
    font-family: 'GothicRegular';
    src: url('/assets/fonts/SocialGothicRegular.otf') format('opentype');
}

@font-face {
    font-family: 'NotoSans';
    src: url('/assets/fonts/NotoSans.ttf') format('truetype');
}

@font-face {
    font-family: 'NotoSans';
    src: url('/assets/fonts/NotoSans-Bold.ttf') format('truetype');
    font-weight: bold;
}
