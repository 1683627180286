@use 'sass:map';
@use '@angular/material' as mat;

@mixin content-card-element-component($theme) {
    $secondary: map.get($theme, 'secondary');
    $gray: map.get($theme, 'grey');

    .content-card-element {
        &__index,
        &__subtitle {
            color: mat.get-color-from-palette($secondary);
        }

        &__content {
            border: 1px solid mat.get-color-from-palette($gray, 100);
        }
    }
}
