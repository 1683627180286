/**
 * Own theme styles
 * Exemple for create custom themes
 */

@import '../../app/room/dialogs/appointment/appointment.dialog.theme';
@include appointment-dialog-component-theme($theme);

@import '../../app/room/containers/reaction/reaction.container.theme';
@include reaction-container-theme($theme);

@import '../../app/shared-modules/timepicker/components/timepicker/timepicker.component.theme';
@include timepicker-component-theme($theme);

@import '../../app/room/components/sidenav/sidenav.component.theme';
@include sidenav-component-theme($theme);

@import '../../app/room/containers/additional-tools/additional-tools.component.theme';
@include additional-tools-component($theme);

@import '../../app/room/components/mentor-script/mentor-script.component.theme';
@include mentor-script-component-theme($theme);

@import '../../app/room/containers/end-session/end-session.component.theme';
@include end-session-component-theme($theme);

@import '../../app/room/containers/feedback/feedback.component.theme';
@include feedback-component-theme($theme);

@import '../../app/room/modules/slideshow/containers/slideshow-navigation/slideshow-navigation.component.theme';
@include slideshow-navigation-component-theme($theme);

@import '../../app/room/modules/documents/containers/documents-list/documents-list.container.theme';
@include documents-list-container($theme);

@import '../../app/room/modules/documents/containers/document-editor/document-editor.container.theme';
@include document-editor-container($theme);

@import '../../app/room/modules/chat/containers/chat/chat.container.theme';
@include chat-container-theme($theme);

@import '../../app/room/modules/video-call/components/call-interface/call-interface.component.theme';
@include call-interface-component($theme);

@import '../../app/shared-modules/datepicker/components/datepicker/datepicker.component.theme';
@include datepicker-component-theme($theme);

@import '../../app/room/modules/slideshow/containers/sections/sections.component.theme';
@include sections-component-theme($theme);

@import '../../app/shared-modules/content-view/components/title-element/title-element.component.theme';
@include title-element-component-theme($theme);

@import '../../app/shared-modules/content-view/components/multiline-title-element/multiline-title-element.component.theme';
@include multiline-title-element-component-theme($theme);

@import '../../app/shared-modules/content-view/components/image-element/image-element.component.theme';
@include image-element-component-theme($theme);

@import '../../app/shared-modules/content-view/components/content-card-element/content-card-element.component.theme';
@include content-card-element-component($theme);

@import '../../app/shared-modules/content-view/components/quote-element/quote-element.component.theme';
@include quote-element-component($theme);

@import '../../app/shared-modules/content-view/components/list-element/list-element.component.theme';
@include list-element-component($theme);

@import '../../app/shared-modules/content-view/components/list-card-element/list-card-element.component.theme';
@include list-card-element-component($theme);

@import '../../app/shared-modules/content-view/components/aspect-card-element/aspect-card-element.component.theme';
@include aspect-card-element-component($theme);

@import '../../app/shared-modules/content-view/components/slider-element/slider-element.component.theme';
@include slider-element-component($theme);

@import '../../app/shared-modules/content-view/components/text-element/text-element.component.theme';
@include text-element-theme($theme);

@import '../../app/shared-modules/timeline/components/timeline/timeline.component.theme';
@include timeline-theme($theme);

@import '../../app/shared-modules/content-view/components/table-element/table-element.component.theme';
@include table-element-theme($theme);

/**
 * Header styles
 */

@import '../../app/header/containers/header/header.container.theme';
@include header-theme($theme);

@import '../../app/shared-modules/participants/components/participant/participant.component.theme';
@include participant-component-theme($theme);

@import '../../app/header/components/classroom-navigation/classroom-navigation.component.theme';
@include classroom-navigation-component-theme($theme);

@import '../../app/room/modules/slideshow/containers/breadcrumbs/breadcrumbs.container.theme';
@include header-breadcrumbs-theme($theme);

@import '../../app/shared-modules/settings/dialogs/settings/settings.dialog.theme';
@include settings-dialog-theme($theme);

@import '../../app/room/modules/video-call/dialogs/bad-connection-help/bad-connection-help.dialog.theme';
@include bad-connection-help-dialog($theme);

/**
    ADMIN
 */

@import '../../app/admin/modules/slide-edit/components/slide-row-edit/slide-row-edit.component.theme';
@include slide-row-edit-component-theme($theme);

@import '../../app/admin/modules/slide-edit/components/slide-column-edit/slide-column-edit.component.theme';
@include slide-column-edit-component-theme($theme);
