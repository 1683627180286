@use 'sass:map';
@use '@angular/material' as mat;

@mixin appointment-dialog-component-theme($theme) {
    $primary: map.get($theme, 'primary');
    $secondary: map.get($theme, 'secondary');
    $grey: map.get($theme, 'grey');

    .actions {
        .mat-stroked-button {
            border-color: mat.get-color-from-palette($grey, 300);
            color: mat.get-color-from-palette($grey, 300);
        }
    }

    .carousel {
        .timepicker-list {
            border-left: 1px solid mat.get-color-from-palette($grey, 300);
            border-top: 1px solid mat.get-color-from-palette($grey, 300);
        }

        .timepicker-item {
            border-right: 1px solid mat.get-color-from-palette($grey, 300);
            border-bottom: 1px solid mat.get-color-from-palette($grey, 300);

            &:hover {
                background-color: mat.get-color-from-palette($grey, 50);
            }

            &.available {
                color: mat.get-color-from-palette($primary);
            }

            &.is-active {
                background-color: mat.get-color-from-palette($secondary);
            }
        }
    }
}
