.mat-icon {
    &.small {
        font-size: 22px;
        height: 22px;
        width: 22px;
    }
    &.medium {
        font-size: 28px;
        height: 28px;
        width: 28px;
    }
    &.large {
        font-size: 36px;
        height: 36px;
        width: 36px;
    }
}
