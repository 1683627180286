@use 'sass:map';
@use '@angular/material' as mat;

@mixin documents-list-container($theme) {
    $primary: map.get($theme, 'primary');
    $secondary: map.get($theme, 'secondary');
    $grey: map.get($theme, 'grey');

    .documents-list {
        &__date {
            color: mat.get-color-from-palette($grey, 200);
        }

        &__list .mat-list .list-item {
            border-bottom: 1px solid mat.get-color-from-palette($grey, 200);

            &:hover {
                background: mat.get-color-from-palette($grey, 50);
            }

            .icon {
                background: mat.get-color-from-palette($secondary);
            }
        }
    }
}
