@use 'sass:map';
@use '@angular/material' as mat;

@mixin participant-component-theme($theme) {
    $primary: map.get($theme, 'primary');
    $warn: map.get($theme, 'warn');
    $grey: map.get($theme, 'grey');

    .participant {
        &__connection-level {
            background-color: mat.get-color-from-palette($grey, 200);

            &--low {
                background-image: radial-gradient(
                    circle at bottom,
                    mat.get-color-from-palette($grey) 0%,
                    mat.get-color-from-palette($grey) 33%,
                    mat.get-color-from-palette($grey, 200) 33%,
                    mat.get-color-from-palette($grey, 200) 100%
                );
            }

            &--mid {
                background-image: radial-gradient(
                    circle at bottom,
                    mat.get-color-from-palette($grey) 0%,
                    mat.get-color-from-palette($grey) 55%,
                    mat.get-color-from-palette($grey, 200) 55%,
                    mat.get-color-from-palette($grey, 200) 100%
                );
            }

            &--high {
                background-color: mat.get-color-from-palette($grey);
            }
        }

        &__avatar-container {
            &:after {
                background-color: mat.get-color-from-palette($warn);
            }

            &--online::after {
                background-color: mat.get-color-from-palette($primary);
            }
        }

        &__status {
            color: mat.get-color-from-palette($grey, 300);
        }
    }
}
