@use 'sass:map';
@use '@angular/material' as mat;

@mixin datepicker-component-theme($theme) {
    $primary: map.get($theme, 'primary');
    $secondary: map.get($theme, 'secondary');

    .mat-customize {
        .mat-calendar-body-cell-content {
            &.info-point {
                color: mat.get-color-from-palette($primary);
            }
        }

        .mat-calendar-body-selected {
            &.mat-calendar-body-cell-content {
                background: mat.get-color-from-palette($secondary);
            }
            &:hover {
                background: mat.get-color-from-palette(
                    $secondary,
                    300
                ) !important;
            }
        }
    }
}
