@use 'sass:map';
@use '@angular/material' as mat;

@mixin list-element-component($theme) {
    $secondary: map.get($theme, 'secondary');

    .list-element {
        &__list-icon,
        &__list-title {
            color: mat.get-color-from-palette($secondary);
        }

        &__sub-list-icon {
            background: mat.get-color-from-palette($secondary);
        }
    }
}
