@use '@angular/material' as mat;

/* Hex #00b1a7 */
$my-primary: (
    50: #e0f6f4,
    100: #b3e8e5,
    200: #80d8d3,
    300: #4dc8c1,
    400: #26bdb4,
    500: #00b1a7,
    600: #00aa9f,
    700: #00a196,
    800: #00988c,
    900: #00887c,
    A100: #b4fff7,
    A200: #81fff2,
    A400: #4effec,
    A700: #35ffe9,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);
/* Hex #8fced7 */
$my-secondary: (
    50: #f2f9fa,
    100: #ddf0f3,
    200: #c7e7eb,
    300: #b1dde3,
    400: #a0d5dd,
    500: #8fced7,
    600: #91bbbf,
    700: #7cc2cd,
    800: #72bcc7,
    900: #60b0be,
    A100: #ffffff,
    A200: #f7feff,
    A400: #c4f6ff,
    A700: #abf2ff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #000000,
        900: #000000,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);
/* Hex #ffca3e */
$my-accent: (
    50: #fff9e8,
    100: #ffefc5,
    200: #ffe59f,
    300: #ffda78,
    400: #ffd25b,
    500: #ffca3e,
    600: #ffc538,
    700: #ffbd30,
    800: #ffb728,
    900: #ffab1b,
    A100: #ffffff,
    A200: #f6f2e9,
    A400: #ffebcc,
    A700: #ffe1b3,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #000000,
        900: #000000,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);
/* Hex #e7695d */
$my-warn: (
    50: #fcedec,
    100: #f8d2ce,
    200: #f3b4ae,
    300: #ee968e,
    400: #eb8075,
    500: #e7695d,
    600: #e46155,
    700: #e0564b,
    800: #dd4c41,
    900: #d73b30,
    A100: #ffffff,
    A200: #ffe9e7,
    A400: #ffb8b4,
    A700: #ffa09b,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

/* Hex #71a4e8 */
$my-info: (
    50: #eef4fc,
    100: #d4e4f8,
    200: #b8d2f4,
    300: #9cbfef,
    400: #86b2eb,
    500: #71a4e8,
    600: #699ce5,
    700: #5e92e2,
    800: #5489de,
    900: #4278d8,
    A100: #ffffff,
    A200: #f7faff,
    A400: #c4d8ff,
    A700: #abc8ff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #000000,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    ),
);

/**
 * Shadows of gray :)
 * 500: PrimaryDark
 * 400: PrimaryGrey
 * 300: SecondaryGrey
 * 200: TertiaryGrey
 * 100: CardGrey
 * 50:  Background
 */
$my-grey: (
    50: #f7f7f7,
    100: #ebebeb,
    200: #e0e0e0,
    300: #c1c1c1,
    400: #6c6c72,
    500: #454545,
    600: #3e3e3e,
    700: #363636,
    800: #2e2e2e,
    900: #1f1f1f,
    A100: #f07a7a,
    A200: #eb4c4c,
    A400: #ff0505,
    A700: #eb0000,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #ffffff,
        A400: #ffffff,
        A700: #ffffff,
    ),
);

/**
 * Typography
 * @link help https://material.angular.io/guide/typography
 * @link UI https://zpl.io/adA9olE
 */

$main-typography: mat.define-typography-config(
    $font-family: 'NotoSans, Arial, sans-serif',
    $headline-6:
        mat.define-typography-level(
            70px,
            70px,
            500,
            'Gothic, Arial, sans-serif'
        ),
    $headline-5:
        mat.define-typography-level(
            56px,
            56px,
            500,
            'Gothic, Arial, sans-serif'
        ),
    $headline-4:
        mat.define-typography-level(
            45px,
            48px,
            500,
            'Gothic, Arial, sans-serif'
        ),
    $headline-3:
        mat.define-typography-level(
            35px,
            43px,
            500,
            'Gothic, Arial, sans-serif'
        ),
    $headline-2: mat.define-typography-level(32px, 48px, 700),
    $headline-1: mat.define-typography-level(32px, 48px, 700),
    $body-1: mat.define-typography-level(16px, 24px, 500),
);

$accent-typography: mat.define-typography-config(
    $font-family: 'STAY, Arial, sans-serif',
    $headline-3: mat.define-typography-level(38px, 57px, 500),
);
