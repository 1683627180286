@use 'sass:map';
@use '@angular/material' as mat;

@mixin bad-connection-help-dialog($theme) {
    $gray: map.get($theme, 'grey');

    .bad-connection-help {
        &__list {
            color: mat.get-color-from-palette($gray, 400);
        }
    }
}
