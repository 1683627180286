@use 'sass:map';
@use '@angular/material' as mat;

@mixin reaction-container-theme($theme) {
    $grey: map.get($theme, 'grey');

    .reaction {
        &__author {
            color: mat.get-color-from-palette($grey, 400);
        }
    }
}
