@use 'sass:map';
@use '@angular/material' as mat;

@mixin slideshow-navigation-component-theme($theme) {
    $primary: map.get($theme, 'primary');
    $accent: map.get($theme, 'accent');
    $grey: map.get($theme, 'grey');

    .slideshow-navigation__progress {
        .mat-headline-2 span {
            color: mat.get-color-from-palette($primary);
        }

        .mat-subheadline {
            color: mat.get-color-from-palette($grey, 300);
        }
    }

    .slideshow-navigation__actions {
        .mat-icon-button {
            &:hover {
                background-color: mat.get-color-from-palette(
                    $accent,
                    50
                ) !important;
                border-color: mat.get-color-from-palette($accent) !important;
                color: mat.get-color-from-palette($accent) !important;
            }
        }
    }
}
