@use 'sass:map';
@use '@angular/material' as mat;

@mixin classroom-navigation-component-theme($theme) {
    $primary: map.get($theme, 'primary');

    .classroom-navigation {
        &__icon-button {
            &:hover {
                color: mat.get-color-from-palette($primary);
            }
        }
    }
    //$warn: map.get($theme, 'warn');
    //$grey: map.get($theme, 'grey');
    //
    //
    //.mat-card-part {
    //    padding: 0px !important;
    //
    //    .contentMenu {
    //        padding: 20px;
    //    }
    //}
    //
    //.mat-menu-panel.mat-card {
    //    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2) !important;
    //    margin-top: 10px;
    //    overflow: unset;
    //
    //    &:before {
    //        content: '';
    //        width: 0;
    //        height: 0;
    //        border-left: 10px solid transparent;
    //        border-right: 10px solid transparent;
    //        border-bottom: 10px solid #ffffff;
    //        position: absolute;
    //        top: -10px;
    //        right: 10px;
    //        -webkit-filter: drop-shadow(0px -1px 1px rgba(0, 0, 0, 0.1));
    //        filter: drop-shadow(0px -1px 1px rgba(0, 0, 0, 0.1));
    //    }
    //}
}
