@use 'sass:map';
@use '@angular/material' as mat;

$config: mat.get-typography-config($theme);
.mat-headline-6 {
    @include mat.typography-level($main-typography, headline-6);
    margin: 0;
}
.mat-headline-5 {
    @include mat.typography-level($main-typography, headline-5);
    margin: 0;
}
.mat-headline-4 {
    @include mat.typography-level($main-typography, headline-4);
    text-transform: uppercase;
    margin: 0;
}
.mat-headline-3 {
    @include mat.typography-level($main-typography, headline-3);
    text-transform: uppercase;
    margin: 0;

    &.accent {
        @include mat.typography-level($accent-typography, headline-3);
    }
}
.mat-headline-2 {
    &.accent {
        @include mat.typography-level($accent-typography, headline-2);
    }
    margin: 0;
}
.mat-headline-1 {
    @include mat.typography-level($main-typography, headline-1);
    text-transform: uppercase;
    margin: 0;
    font-size: 18px;
    &.accent {
        @include mat.typography-level($accent-typography, headline-1);
    }
}

.mat-h1,
.mat-h2,
.mat-h3,
.mat-h4 {
    margin: 0;
    font-weight: 600;
}

.text-preformatted {
    white-space: pre;
}
