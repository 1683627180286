@use 'sass:map';
@use '@angular/material' as mat;

@mixin sections-component-theme($theme) {
    $primary: map.get($theme, 'primary');
    $grey: map.get($theme, 'grey');

    .sections {
        &__link {
            border-bottom: 1px solid mat.get-color-from-palette($grey, 100) !important;
            color: mat.get-color-from-palette($grey, 400);

            &--active {
                box-shadow: inset 0 -2px mat.get-color-from-palette($primary);
                color: mat.get-color-from-palette($primary) !important;
            }

            &:hover {
                color: mat.get-color-from-palette($primary);
            }
        }
    }
}
