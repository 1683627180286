@use 'sass:map';
@use '@angular/material' as mat;

.mat-tab-link {
    text-transform: capitalize;
    color: mat.get-color-from-palette($grey, 400);
    padding: 0 1rem !important;

    &:hover {
        color: mat.get-color-from-palette($primary);
    }
}

.mat-tab-label-active {
    color: mat.get-color-from-palette($primary);
}
