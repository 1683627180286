@use 'sass:map';
@use '@angular/material' as mat;

@mixin chat-container-theme($theme) {
    $primary: map.get($theme, 'primary');
    $secondary: map.get($theme, 'secondary');
    $grey: map.get($theme, 'grey');

    .chat {
        &__message-group {
            &--host {
                .chat__message {
                    background: mat.get-color-from-palette($secondary);
                    border-color: mat.get-color-from-palette(
                        $secondary
                    ) !important;
                }
            }
        }

        &__message-text {
            color: mat.get-color-from-palette($grey, 800);
        }

        &__form {
            border-top: 1px solid mat.get-color-from-palette($grey, 200);
        }
    }
}
