@use 'sass:map';
@use '@angular/material' as mat;
//$gray: map.get($theme, 'gray');

html {
    box-sizing: border-box;
    overflow-y: scroll; /* All browsers without overlaying scrollbars */
    overflow-x: hidden;
    -webkit-text-size-adjust: 100%; /* iOS 8+ */
}
*,
::before,
::after {
    box-sizing: inherit;
}
::before,
::after {
    text-decoration: inherit; /* Inherit text-decoration and vertical align to ::before and ::after pseudo elements */
    vertical-align: inherit;
}
* {
    background-repeat: no-repeat; /* Set `background-repeat: no-repeat` to all elements */
    padding: 0; /* Reset `padding` and `margin` of all elements */
    margin: 0;
}

body {
    background: mat.get-color-from-palette($grey, 50);
    min-height: calc(100 * var(--vh));
}
