@use 'sass:map';
@use '@angular/material' as mat;

@mixin slider-element-component($theme) {
    $secondary: map.get($theme, 'secondary');

    .slider-element {
        .mat-slider-focus-ring,
        .mat-slider-thumb-label,
        .mat-slider-track-fill,
        .mat-slider-thumb {
            background-color: mat.get-color-from-palette($secondary);
        }
    }
}
