/**
 * Customizing Material UI Components
 * @link https://material.angular.io/guide/customizing-component-styles
 */

@import 'buttons';
@import 'cards';
@import 'ck-editor';
@import 'dialogs';
@import 'icons';
@import 'sidebar';
@import 'tabs';
@import 'zendesk';
