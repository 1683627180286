@use 'sass:map';
@use '@angular/material' as mat;

@mixin slide-row-edit-component-theme($theme) {
    $grey: map.get($theme, 'grey');

    .slide-row-edit {
        background: mat.get-color-from-palette($grey, 300);
        color: black;
    }
}
