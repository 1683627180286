@use 'sass:map';
@use '@angular/material' as mat;

@mixin end-session-component-theme($theme) {
    $grey: map.get($theme, 'grey');

    .end-session .mat-stroked-button,
    .end-call .actions .mat-stroked-button {
        border-color: mat.get-color-from-palette($grey, 300);
        color: mat.get-color-from-palette($grey, 300);
    }
}
