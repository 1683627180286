@use 'sass:map';
@use '@angular/material' as mat;

@mixin additional-tools-component($theme) {
    $primary: map.get($theme, 'primary');
    $grey: map.get($theme, 'grey');

    .additional-tools {
        .mat-tab-label {
            color: mat.get-color-from-palette($grey, 300);

            &:hover:enabled {
                color: mat.get-color-from-palette($primary);
            }

            &-active {
                color: mat.get-color-from-palette($primary);
            }
        }
    }
}
