@use 'sass:map';
@use '@angular/material' as mat;

@mixin slide-column-edit-component-theme($theme) {
    $grey: map.get($theme, 'grey');

    .slide-column-edit {
        background: mat.get-color-from-palette($grey, 200);
        color: mat.get-color-from-palette($grey, 800);
    }
}
