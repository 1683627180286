@use '@angular/material' as mat;

.mat-primary {
    &.bg {
        background-color: mat.get-color-from-palette($primary);
        color: #ffffff;
    }
    &-text {
        color: mat.get-color-from-palette($primary, 500);
    }
}

.mat-accent {
    &.mat-flat-button,
    &.mat-raised-button,
    &.mat-fab,
    &.mat-mini-fab,
    &.bg {
        background-color: mat.get-color-from-palette($accent, 500);
        color: #ffffff;
    }
    &-text {
        color: mat.get-color-from-palette($accent, 500);
    }
}
.mat-secondary {
    color: mat.get-color-from-palette($secondary, 500);

    &.mat-flat-button,
    &.mat-raised-button,
    &.mat-fab,
    &.mat-mini-fab,
    &.bg {
        background-color: mat.get-color-from-palette($secondary, 500);
        color: #ffffff;
    }

    &.mat-button,
    &.mat-icon-button,
    &.mat-stroked-button {
        color: mat.get-color-from-palette($secondary, 500);

        .mat-button-focus-overlay {
            background-color: mat.get-color-from-palette($secondary, 500);
        }
    }

    &-text {
        color: mat.get-color-from-palette($secondary, 500);
    }
}

.mat-primaryDark {
    &.mat-flat-button,
    &.mat-raised-button,
    &.mat-fab,
    &.mat-mini-fab,
    &.bg {
        background-color: mat.get-color-from-palette($grey, 500);
        color: #ffffff;
    }

    &.mat-button,
    &.mat-icon-button,
    &.mat-stroked-button {
        color: mat.get-color-from-palette($grey, 500);

        .mat-button-focus-overlay {
            background-color: mat.get-color-from-palette($grey, 500);
        }
    }

    &-text {
        color: mat.get-color-from-palette($grey, 500);
    }
}

.mat-primaryGrey {
    &.mat-flat-button,
    &.mat-raised-button,
    &.mat-fab,
    &.mat-mini-fab,
    &.bg {
        background-color: mat.get-color-from-palette($grey, 400);
        color: #ffffff;
    }

    &-text {
        color: mat.get-color-from-palette($grey, 400);
    }
}

.mat-secondaryGrey {
    color: mat.get-color-from-palette($grey, 300);

    &.mat-flat-button,
    &.mat-raised-button,
    &.mat-fab,
    &.mat-mini-fab,
    &.bg {
        background-color: mat.get-color-from-palette($grey, 300);
        color: #ffffff;
    }

    &-text {
        color: mat.get-color-from-palette($grey, 300);
    }
}

.mat-tertiaryGrey {
    &.mat-flat-button,
    &.mat-raised-button,
    &.mat-fab,
    &.mat-mini-fab,
    &.bg {
        background-color: mat.get-color-from-palette($grey, 200);
        color: mat.get-color-from-palette($grey, 500);
    }

    &-text {
        color: mat.get-color-from-palette($grey, 200);
    }
}

.mat-cardGrey {
    &.mat-flat-button,
    &.mat-raised-button,
    &.mat-fab,
    &.mat-mini-fab,
    &.bg {
        background-color: mat.get-color-from-palette($grey, 100);
        color: mat.get-color-from-palette($grey, 500);
    }

    &-text {
        color: mat.get-color-from-palette($grey, 100);
    }
}

.mat-warn {
    &.mat-flat-button,
    &.mat-raised-button,
    &.mat-fab,
    &.mat-mini-fab,
    &.bg {
        background-color: mat.get-color-from-palette($warn, 500);
        color: #ffffff;
    }
    &-text {
        color: mat.get-color-from-palette($warn, 500);
    }
}

.mat-info {
    &.mat-flat-button,
    &.mat-raised-button,
    &.mat-fab,
    &.mat-mini-fab,
    &.bg {
        background-color: mat.get-color-from-palette($info, 500);
        color: #ffffff;
    }
    &.mat-icon,
    &-text {
        color: mat.get-color-from-palette($info, 500);
    }
}

.mat-background {
    background-color: mat.get-color-from-palette($grey, 50);
    color: mat.get-color-from-palette($grey, 500);
}
