@use 'sass:map';
@use '@angular/material' as mat;

@mixin call-interface-component($theme) {
    $primary: map.get($theme, 'primary');

    .call-interface__subscriber:before {
        box-shadow: inset 0 0 0 2px mat.get-color-from-palette($primary);
    }
}
