@use 'sass:map';
@use '@angular/material' as mat;

@mixin image-element-component-theme($theme) {
    $secondary: map.get($theme, 'secondary');
    $accent: map.get($theme, 'accent');

    .image-element {
        &--slide-image {
            .image-element__decoration-wrapper {
                &:before {
                    background-color: mat.get-color-from-palette($accent, A200);
                }
                &:after {
                    background-color: mat.get-color-from-palette($secondary);
                }
            }
        }
        &--title-image {
            .image-element__outside-decoration-wrapper {
                &:after {
                    background-color: mat.get-color-from-palette($secondary);
                }
            }
        }
    }
}
