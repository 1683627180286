.mat-dialog-actions {
    justify-content: flex-end;
}

.mat-dialog-container {
    padding: 4rem !important;
}

.mat-dialog-title {
    font-size: 30px;
    line-height: 30px;
}
