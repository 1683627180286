@use 'sass:map';
@use '@angular/material' as mat;

@mixin sidenav-component-theme($theme) {
    $primary: map.get($theme, 'primary');
    $grey: map.get($theme, 'grey');

    .sidenav {
        &__button {
            color: mat.get-color-from-palette($grey, 400);

            &:hover:enabled {
                color: mat.get-color-from-palette($primary);

                .mat-button-focus-overlay {
                    background: mat.get-color-from-palette($primary);
                }
            }

            &.active::after {
                background: mat.get-color-from-palette($primary);
            }
        }
    }
}
