@use 'sass:map';
@use '@angular/material' as mat;

@mixin feedback-component-theme($theme) {
    $grey: map.get($theme, 'grey');

    .feedback {
        &__input-group-label {
            color: mat.get-color-from-palette($grey, 400);
        }
    }
}
