@use 'sass:map';
@use '@angular/material' as mat;

@mixin timeline-theme($theme) {
    $secondary: map.get($theme, 'secondary');

    .timeline {
        &__point {
            &:before {
                background: mat.get-color-from-palette($secondary);
            }
        }
    }
}
