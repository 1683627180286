@use 'sass:map';
@use '@angular/material' as mat;

@mixin header-theme($theme) {
    $config: mat.get-typography-config($theme);

    .mat-toolbar {
        .header {
            &__title {
                @include mat.typography-level($config, headline-3);
            }
        }
    }
}
