@use 'sass:map';
@use '@angular/material' as mat;

@mixin text-element-theme($theme) {
    $secondary: map.get($theme, 'secondary');

    .ck-content {
        h1,
        h2,
        h3,
        h4,
        h5 {
            color: mat.get-color-from-palette($secondary);
        }
    }
}
