@use 'sass:map';
@use '@angular/material' as mat;

@mixin quote-element-component($theme) {
    $gray: map.get($theme, 'grey');
    $secondary: map.get($theme, 'secondary');

    .quote-element {
        &__icon {
            background: mat.get-color-from-palette($secondary);
            &:before {
                border-bottom-color: mat.get-color-from-palette(
                    $secondary
                ) !important;
            }
        }
        &__author {
            color: mat.get-color-from-palette($gray, 300);
        }
    }
}
