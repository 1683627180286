@use 'sass:map';
@use '@angular/material' as mat;

@mixin header-breadcrumbs-theme($theme) {
    $primary: map.get($theme, 'primary');
    $grey: map.get($theme, 'grey');

    .breadcrumbs {
        &__link {
            color: mat.get-color-from-palette($grey, 400);

            &:last-child,
            &:hover {
                color: mat.get-color-from-palette($primary);
            }
        }

        &__button {
            color: mat.get-color-from-palette($grey, 400);

            &:hover {
                color: mat.get-color-from-palette($primary);
            }
        }

        &__link-icon {
            color: mat.get-color-from-palette($grey, 400);
        }
    }
}
