@use 'sass:map';
@use '@angular/material' as mat;

@mixin title-element-component-theme($theme) {
    $secondary: map.get($theme, 'secondary');

    .title-element {
        &__word {
            &--painted {
                color: mat.get-color-from-palette($secondary);
            }
        }
    }
}
